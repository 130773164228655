import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueLazyLoad from 'vue-lazyload'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(VueLazyLoad)
Vue.config.productionTip = false

const messages = {
  bg: {
    message: {
      title: 'Къмпинг Иракли',
      short_title: 'Иракли',
      title_wild: 'Къмпинг Иракли Див',
      short_title_wild: 'Див',
      info: 'Информация',
      gallery: 'Галерия',
      prices: {
        text: 'Цени',
        camper: '15лв',
        camper_high: '20лв',
        tent_small: '6лв',
        tent_small_high: '8лв',
        tent_big: '10лв',
        tent_big_high: '10лв',
        person: '7лв',
        adult: '8лв',
        child: '4лв',
        adult_high: '10лв',
        child_high: '5лв',
        car: '5лв',
        bike: '2лв'
      },
      location: {
        text: 'Местоположение',
        coords: 'координати',
        map: 'Карта'
      },
      description: '<p>Къмпинг Иракли се намира на 50 м. от едноименния плаж. Разположен е в северната му част на място, откъдето се разкрива панорамна гледка към морето.</p>'
        + '<p>Къмпингът предлага затревени площи за настаняване на кемпери, каравани и палатки. За всяко място е осигурен достъп до електричество и вода, както и ползване на санитарен възел и душ.</p>'
        + '<p>На територията на къмпинга има Wi-Fi, магазин за хранителни стоки, кафе автомат, пералня, както и барбекюта за общо ползване. На пешеходно разстояние има ресторант.</p>'
        + '<br/><p>За резервации:</p>'
        + '<p><a style="color: black !important;" href="tel:+359888221676">0888-221-676</a></p>'
        + '<p><a style="color: black !important;" href="tel:+359887645400">0887-645-400</a></p>'
        + '<p><a style="color: black !important;" href="mailto:campingirakli@mail.bg">campingirakli@mail.bg</a></p>'
        + '<p><a style="color: black !important;" href="https://campirakli.com" target="_blank">campirakli.com</a></p>',
      description_wild: '<p>Обновеният през 2021 Къмпинг Иракли Див вече предлага по-добри условия за почивка сред природата.</p>'
        + '<p>Боксове с мивки за измиване на посуда, кранчета с бързи връзки за захранваща вода за кемпери, мъжки и женски тоалетни, както и бани с постоянна топла вода.</p>'
        + '<p>Ток за зареждане на телефони и други слаби консуматори може да се ползва 2 часа дневно.</p>'
        + '<p>Терените са почистени и тревата е изкосена. Организирано е сметоизвозване веднъж дневно.</p>'
        + '<p>Директния достъп до плажа е по път не по-дълъг от 30м, което прави сенчестите терени достатъчно удобни и приятни за почивка.</p>'
        + '<p>При настаняване и избор на място е съобразена необходимата дистанция от други почиващи в къмпинга, за да се осигури нужното спокойствие.</p>'
        + '<p>Позволен е престоят с безпроблемни и неагресивни домашни любимци.</p>'
        + '<p>Ако желаете да получите повече информация или имате интерес, може да ни потърсите на нашата facebook страница или на посочения телефон за контакт.</p>'
        + '<br/><p>За резервации:</p>'
        + '<p><a style="color: black !important;" href="tel:+359896705545">0896-705-545</a></p>'
        + '<p><a style="color: black !important;" href="mailto:campingirakli@mail.bg">campingirakli@mail.bg</a></p>'
        + '<p><a style="color: black !important;" href="https://campingirakliwild.com" target="_blank">campingirakliwild.com</a></p>',
      price: {
        heading: 'Цени за сезон 2022',
        period: 'Период',
        jun_sept: 'юни, септември',
        jul_aug: 'юли, август',
        camper: 'Каравана/кемпер',
        tent_small: 'Малка палатка (до 4м<sup>2</sup>)',
        tent_big: 'Голяма палатка (над 4м<sup>2</sup>)',
        accomodation: 'Престой',
        person: 'човек',
        adult: 'Възрастен над 7г',
        child: 'Дете от 4 до 7г',
        parking: 'Паркинг',
        car: 'Автомобил',
        bike: 'Мотоциклет'
      }
    }
  },
  en: {
    message: {
      title: 'Camping Irakli',
      short_title: 'Irakli',
      title_wild: 'Camping Irakli Wild',
      short_title_wild: 'Wild',
      info: 'Information',
      gallery: 'Gallery',
      prices: {
        text: 'Prices',
        camper: '€7.50',
        camper_high: '€10',
        tent_small: '€3',
        tent_small_high: '€4',
        tent_big: '€5',
        tent_big_high: '€5',
        person: '€3.50',
        adult: '€4',
        child: '€2',
        adult_high: '€5',
        child_high: '€2.50',
        car: '€2.50',
        bike: '€1'
      },
      location: {
        text: 'Location',
        coords: 'coordinates',
        map: 'Map'
      },
      description: '<p>Camping Irakli is located 50 meters from the beach of the same name. It is located in its northern part in a place with a panoramic view of the sea.</p>'
        + '<p>The campsite offers grassy areas for campers, caravans and tents. Each place is provided with access to electricity and water, as well as usage of bathroom and shower.</p>'
        + '<p>On the territory of the campsite there is Wi-Fi, grocery store, coffee machine, laundry, and barbecues. There is a restaurant within walking distance.</p>'
        + '<br/><p>Bookings:</p>'
        + '<p><a style="color: black !important;" href="tel:+359896705545">(+359)896-705-545</a> - for English</p>'
        + '<p><a style="color: black !important;" href="tel:+359888221676">(+359)888-221-676</a></p>'
        + '<p><a style="color: black !important;" href="tel:+359887645400">(+359)887-645-400</a></p>'
        + '<p><a style="color: black !important;" href="mailto:campingirakli@mail.bg">campingirakli@mail.bg</a></p>'
        + '<p><a style="color: black !important;" href="https://campirakli.com" target="_blank">campirakli.com</a></p>',
      description_wild: '<p>Renewed in 2021, Camping Irakli Wild now offers better condititions.</p>'
        + '<p>Sinks for washing dishes, water supply hookups for campers, men and women restrooms, showers with hot water.</p>'
        + '<p>Electricity for mobile phone charging and other weak consumers can be used for 2 hours daily.</p>'
        + '<p>The places are cleaned and the grass has been mown. Grabage collection is organized once a day.</p>'
        + '<p>The direct access to the beach is by a road not longer than 30m, which makes the shady terrains comfortable and pleasant enough for rest.</p>'
        + '<p>When choosing a place, the necessary distance from others staying in the campsite is taken into account.</p>'
        + '<p>It is allowed to stay with non-aggressive pets.</p>'
        + '<p>If you are interested, you can contact us on our facebook page or by the phone.</p>'
        + '<br/><p>Bookings:</p>'
        + '<p><a style="color: black !important;" href="tel:+359896705545">(+359)896-705-545</a></p>'
        + '<p><a style="color: black !important;" href="mailto:campingirakli@mail.bg">campingirakli@mail.bg</a></p>'
        + '<p><a style="color: black !important;" href="https://campingirakliwild.com" target="_blank">campingirakliwild.com</a></p>',
      price: {
        heading: 'Prices for season 2022',
        period: 'Period',
        jun_sept: 'June, September',
        jul_aug: 'July, August',
        camper: 'Camper/RV',
        tent_small: 'Small tent (up to 4m<sup>2</sup>)',
        tent_big: 'Big tent (over 4m<sup>2</sup>)',
        accomodation: 'Accomodation',
        person: 'person',
        adult: 'Adult over 7y',
        child: 'Child under 7y',
        parking: 'Parking lot',
        car: 'Car',
        bike: 'Motorcycle'
      }
    }
  }
}

const i18n = new VueI18n({
  locale: 'bg',
  messages
})

new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
