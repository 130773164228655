<template>
  <b-navbar toggleable="lg" fixed="top">
    <b-navbar-brand href="#">Къмпинг Иракли</b-navbar-brand>
    <b-navbar-toggle target="menu"></b-navbar-toggle>
    <b-collapse id="menu" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-for="(lang, i) in langs" :key="`Lang${i}`" v-on:click="$root.$i18n.locale = lang">{{ lang | upper }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      langs: ['bg', 'en']
    };
  },
};
</script>

<style>
  nav {
    background: linear-gradient(rgba(80, 80, 80, 0.55), rgba(50, 50, 50, 0.55)) !important;
    border-bottom: 1px solid #000;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
  }
  a {
    color: #FFF !important;
    font-family: Century Gothic, sans-serif;
    font-size: medium;
    line-height: 32px !important;
  }
  a:hover {
    color: #EEE !important;
    text-decoration: underline !important;
  }
  .dropdown-menu {
    background: linear-gradient(rgba(80, 80, 80, 0.8), rgba(50, 50, 50, 0.8)) !important;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
    margin-left: -150px;
  }
  .dropdown-menu a:hover {
    background: none;
  }
</style>