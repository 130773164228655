<template>
  <div id="app">
    <CampIrakli />
  </div>
</template>

<script>
import CampIrakli from './components/CampIrakli.vue'

export default {
  name: 'App',
  components: {
    CampIrakli
  }
}
</script>

<style>
#app {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media (max-width: 990px) {
  #app {
    /* position: absolute; */
  }
}
</style>
