<template>
  <div>
    <Navbar v-show="mode != 'mobile'" />
    <b-container v-show="mode == 'mobile'" class="mobile" fluid>
      <b-row class="topRow">
        <h2>Camping Irakli</h2>
        <div class="imageSlider">
          <VueSlickCarousel :arrows="false" :draggable="false" :autoplay="true" :pauseOnHover="false" :swipe="false"
            :touchMove="false" :centerMode="true" :speed="1500" :slidesToShow="1" :centerPadding="20"
            :variableWidth="true">
            <div v-for="(image, index) in irakli.media" :key="index">
              <img class="image" @click="openGallery('irakli', index)" v-lazy="image.thumb" />
            </div>
          </VueSlickCarousel>
        </div>
        <div class="mobileMap">
          <iframe
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDAwtsmcNVim25XQ2pqvUSGVvHfPo5OYYY&maptype=satellite&q=Къмпинг+Иракли"
            style="border:0; width: 100%; height: 20vh;" allowfullscreen="" width="100%" height="100%"
            frameborder="0"></iframe>
        </div>
      </b-row>
      <b-row class="bottomRow">
        <h2>Camping Irakli Wild</h2>
        <div class="imageSlider">
          <VueSlickCarousel :arrows="false" :draggable="false" :autoplay="true" :pauseOnHover="false" :swipe="false"
            :touchMove="false" :centerMode="true" :speed="1500" :slidesToShow="1" :centerPadding="20"
            :variableWidth="true">
            <div v-for="(image, index) in wild.media" :key="index">
              <img class="image" @click="openGallery('wild', index)" v-lazy="image.thumb" />
            </div>
          </VueSlickCarousel>
        </div>
        <div class="mobileMap">
          <iframe
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDAwtsmcNVim25XQ2pqvUSGVvHfPo5OYYY&maptype=satellite&q=Camping+Irakli+Wild"
            style="border:0; width: 100%; height: 20vh;" allowfullscreen="" width="100%" height="100%"
            frameborder="0"></iframe>
        </div>
      </b-row>
    </b-container>
    <b-container v-show="mode != 'mobile'" class="content" fluid="md">
      <b-row class="campnavbox">
        <b-col cols="12" v-show="mode == 'irakli'">
          <b-jumbotron class="noselect">
            <h5>{{ $t('message.title') }} временно не работи.</h5>
          </b-jumbotron>
        </b-col>

        <b-col cols="12" v-show="mode == 'wild'">
          <b-jumbotron class="noselect">
            <h5>{{ $t('message.title_wild') }}</h5>
            <b-button-group>
              <b-button :pressed="wild.toggled == 'info'" v-on:click="toggle(wild, 'info')" variant="primary">
                {{ $t('message.info') }}</b-button>
              <b-button :pressed="wild.toggled == 'gallery'" v-on:click="toggle(wild, 'gallery')" variant="primary">
                {{ $t('message.gallery') }}</b-button>
            </b-button-group>
            <b-button-group>
              <b-button :pressed="wild.toggled == 'prices'" v-on:click="toggle(wild, 'prices')" variant="primary">
                {{ $t('message.prices.text') }}</b-button>
              <b-button :pressed="wild.toggled == 'location'" v-on:click="toggle(wild, 'location')" variant="primary">
                {{ $t('message.location.text') }}</b-button>
            </b-button-group>
            <div class="toggle-content" v-show="wild.toggled == 'info'" v-html="$t('message.description_wild')"></div>
            <div class="toggle-content" v-show="wild.toggled == 'gallery'">
              <light-box ref="wild" :media="wild.media" :show-light-box="false"></light-box>
              <b-container class="images-wrapper">
                <b-form-row>
                  <b-col :cols="4" v-for="(image, index) in wild.media" :key="index">
                    <img class="image" @click="openGallery('wild', index)" v-lazy="image.thumb" />
                  </b-col>
                </b-form-row>
              </b-container>
            </div>
            <div class="toggle-content" v-show="wild.toggled == 'prices'">
              <table class="table table-hover" style="text-align: center">
                <thead>
                  <tr>
                    <th colspan="2">
                      <h4><span itemprop="name">{{ $t('message.price.heading') }}</span></h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-right">{{ $t('message.price.camper') }}:</td>
                    <td><span itemprop="price">{{ $t('message.prices.camper') }}</span><sup>1</sup></td>
                  </tr>
                  <tr>
                    <td class="text-right"><span v-html="$t('message.price.tent_small')"></span>:</td>
                    <td>{{ $t('message.prices.tent_small') }}<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td class="text-right"><span v-html="$t('message.price.tent_big')"></span>:</td>
                    <td>{{ $t('message.prices.tent_big') }}<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td colspan="2"><sup>1</sup><strong>{{ $t('message.price.accomodation') }}</strong></td>
                  </tr>
                  <tr>
                    <td class="text-right">{{ $t('message.price.adult') }}:</td>
                    <td>{{ $t('message.prices.person') }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">{{ $t('message.price.child') }}:</td>
                    <td>{{ $t('message.prices.child_high') }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>{{ $t('message.price.parking') }}</strong></td>
                  </tr>
                  <tr>
                    <td class="text-right">{{ $t('message.price.car') }}:</td>
                    <td>{{ $t('message.prices.car') }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">{{ $t('message.price.bike') }}:</td>
                    <td>{{ $t('message.prices.bike') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="toggle-content" v-show="wild.toggled == 'location'">
              <p>GPS {{ $t('message.location.coords') }}: 42°44'40.5"N 27°53'18.3"E (42.744583, 27.888426)</p>
              <div class="container">
                <p>{{ $t('message.location.map') }}:</p>
                <iframe
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDAwtsmcNVim25XQ2pqvUSGVvHfPo5OYYY&maptype=satellite&q=Camping+Irakli+Wild"
                  style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" width="100%" height="400"
                  frameborder="0"></iframe>
              </div>
            </div>
          </b-jumbotron>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
import Navbar from './Navbar'
import LightBox from 'vue-image-lightbox'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'animate.css';

Vue.filter('upper', function (val) {
  return val.toUpperCase();
})

export default {
  name: 'CampIrakli',
  components: {
    Navbar,
    LightBox,
    VueSlickCarousel,
  },
  data() {
    return {
      mode: 'irakli',
      irakli: {
        toggled: 'info',
        media: [
          {
            thumb: 'bgs/1.jpg',
            src: 'bgs/1.jpg'
          },
          {
            thumb: 'bgs/2.jpg',
            src: 'bgs/2.jpg'
          },
          {
            thumb: 'bgs/3.jpg',
            src: 'bgs/3.jpg'
          },
          {
            thumb: 'bgs/4.jpg',
            src: 'bgs/4.jpg'
          },
          {
            thumb: 'bgs/5.jpg',
            src: 'bgs/5.jpg'
          },
          {
            thumb: 'bgs/6.jpg',
            src: 'bgs/6.jpg'
          },
        ],
      },
      wild: {
        toggled: 'info',
        media: [
          {
            thumb: 'bgs/wild/PTL.jpg',
            src: 'bgs/wild/PTL.jpg'
          },
          {
            thumb: 'bgs/wild/OXZ.jpg',
            src: 'bgs/wild/OXZ.jpg'
          },
          {
            thumb: 'bgs/wild/RME.jpg',
            src: 'bgs/wild/RME.jpg'
          },
          {
            thumb: 'bgs/wild/KIL.jpg',
            src: 'bgs/wild/KIL.jpg'
          },
          {
            thumb: 'bgs/wild/RIN.jpg',
            src: 'bgs/wild/RIN.jpg'
          },

          {
            thumb: 'bgs/wild/Z15.jpg',
            src: 'bgs/wild/Z15.jpg'
          },
          {
            thumb: 'bgs/wild/XO6.jpg',
            src: 'bgs/wild/XO6.jpg'
          },
          {
            thumb: 'bgs/wild/ZX0.jpg',
            src: 'bgs/wild/ZX0.jpg'
          },
          {
            thumb: 'bgs/wild/1C0.jpg',
            src: 'bgs/wild/1C0.jpg'
          },
          // {
          //   thumb: 'bgs/wild/10.jpg',
          //   src: 'bgs/wild/10.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/11.jpg',
          //   src: 'bgs/wild/11.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/12.jpg',
          //   src: 'bgs/wild/12.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/13.jpg',
          //   src: 'bgs/wild/13.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/14.jpg',
          //   src: 'bgs/wild/14.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/15.jpg',
          //   src: 'bgs/wild/15.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/16.jpg',
          //   src: 'bgs/wild/16.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/17.jpg',
          //   src: 'bgs/wild/17.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/15.jpg',
          //   src: 'bgs/wild/15.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/18.jpg',
          //   src: 'bgs/wild/18.jpg'
          // },
          // {
          //   thumb: 'bgs/wild/19.jpg',
          //   src: 'bgs/wild/19.jpg'
          // },
        ],
      },
      toggle: function (site, page) {
        site.toggled = page;
      }
    };
  },
  methods: {
    openGallery(ref, index) {
      this.$refs[ref].showImage(index);
    }
  }
};
</script>

<style scoped>
.content {
  margin-top: 100px;
}

p {
  text-align: justify;
}

.btn-group {
  margin-bottom: 20px;
}

.jumbotron {
  /* background: rgba(236, 236, 236, 0.4); */
  color: rgb(29, 29, 29);
  text-shadow: 2px 1px 5px rgba(220, 220, 220, 0.85);
  /* height: 800px; */
}

.jumbotron:hover {
  /* background: rgba(236, 236, 236, 0.7); */
  /* box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.55); */
}

h5>a {
  color: black !important;
  font-size: 3em;
}

.noselect {
  user-select: none;
}

.campnavbox {
  /* background-image: url(../../public/bg.png);
  background-position: center;
  background-size: auto 1034px;
  background-clip: border-box; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.55); */
  padding-top: 20px;
  /* border-radius: 15px; */
}

/* .toggle-content {
  max-height: 520px;
  overflow-y: auto;
} */

.images-wrapper {
  width: 100%;
}

.images-wrapper .image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.table td {
  padding: 0.4rem;
}

.table .text-right {
  overflow: visible;
  white-space: nowrap;
}

.mobile {
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 200;
  background: rgb(60, 60, 60);
}

.mobile .topRow,
.mobile .bottomRow {
  height: 50vh;
}

.mobile h2 {
  width: 100%;
  text-align: center;
  color: white;
  height: 5vh;
  line-height: 5vh;
  font-size: 4vh;
}

.mobile .imageSlider {
  width: 100%;
  height: 25vh;
  position: absolute;
}

.mobile .topRow .imageSlider {
  top: 5vh;
}

.mobile .bottomRow .imageSlider {
  top: 55vh;
}

.mobile .imageSlider img {
  /* max-width: 18vw; */
  height: 25vh;
  width: auto;
}

.mobileMap {
  height: 20vh;
  width: 100%;
  position: absolute;
}

.mobile .topRow .mobileMap {
  top: 30vh;
}

.mobile .bottomRow .mobileMap {
  top: 80vh;
}

/* @media (max-width: 990px) {
  .campnavbox {
    background-image: none;
  }

  .campnavbox>div {
    background-image: url(../../public/bg.png);
    background-size: auto 1034px;
  }

  .campnavbox>div:first-child {
    background-position: -150px;
  }

  .campnavbox>div:nth-child(2) {
    background-position: -550px;
  }
}

@media (max-width: 660px) {
  .campnavbox>div:nth-child(2) {
    background-position: -900px;
  }
} */
</style>